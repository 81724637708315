import React from 'react'

function Settings() {
  return (
    <div className='h-screen flex items-center justify-center'>
        <h1 className='text-3xl text-center'>Coming soon...</h1>
    </div>
  )
}

export default Settings