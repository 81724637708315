import MUIGridGloabl from 'components/MuiGrid/MUIGlobalGrid';
import React from 'react';

function LineItems({itemsData} : ILineItems) {
	const columns = [
		{
			headerName: 'Item ID',
			field: 'id',
			flex: 1,
		},
		{
			headerName: 'Item Name',
			field: 'ProductLabel',
			flex: 1,
		},
		{
			headerName: 'Quantity',
			field: 'Quantity',
			flex: 1,
		},
		{
			headerName: 'Price',
			field: 'UnitPrice',
			flex: 1,
		},
		{
			headerName: 'Total',
			field: 'ItemAmount',
			flex: 1,
		},
	];
	return (
		<div className='mt-5'>
			<MUIGridGloabl
				columns={columns}
				dataSource={itemsData}
				rowCount={itemsData.length}
			/>
		</div>
	);
}

export default LineItems;

export interface ILineItems{
    itemsData : any;
}
