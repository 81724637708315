import { SHOW_LOADER , HIDE_LOADER} from './AlertActionTypes';

interface IAlert {
    loading: boolean
}

const initialState: IAlert = {
    loading: false
}

const alertsReducer = (state: any = initialState, action: any): IAlert => {
    switch (action.type) {
        case SHOW_LOADER:
            return { ...state, loading:true };
            case HIDE_LOADER:
                return { ...state, loading:false };
        default:
            return state;
    }
}

export default alertsReducer