import {
	LayoutDispatchTypes,
	TOGGLE_COLLAPSE,
	TOGGLE_HEADER_NAV,
	RELOAD_LAYOUT,
} from './LayoutActionTypes';

interface ILayout {
	Collapse: boolean;
	HeaderNav: boolean;
	Reload_Layout: boolean;
}

const initialState: ILayout = {
	Collapse: false,
	HeaderNav: true,
	Reload_Layout: true,
};

const layoutReducer = (
	state: any = initialState,
	action: LayoutDispatchTypes
): ILayout => {
	switch (action.type) {
		case TOGGLE_COLLAPSE:
			return { ...state, Collapse: !state.Collapse };
		case TOGGLE_HEADER_NAV:
			return { ...state, HeaderNav: action.payload };
		case RELOAD_LAYOUT:
			return { ...state, Reload_Layout: !state.Reload_Layout };
		default:
			return state;
	}
};

export default layoutReducer;
