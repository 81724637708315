import React, { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';

export interface MUIGridPaginationProps {
	onPageChange: any;
	onRowsPerPageChange: any;
	totalRows: number;
	totalPages: number;
	pageSize: number;
	pageNum: number;
}

const MUIGridPagination = ({
	onPageChange,
	onRowsPerPageChange,
	totalRows,
	totalPages,
	pageSize,
	pageNum,
}: MUIGridPaginationProps) => {
	const [page, setPage] = useState<number>(pageNum);
	const [rowsPerPage, setRowsPerPage] = useState(pageSize);
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		onRowsPerPageChange(Number(event.target.value));
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		const { title } = event.currentTarget;
		if (title === 'Go to next page') {
			onPageChange('next', newPage);
		} else if (title === 'Go to previous page') {
			onPageChange('previous', newPage);
		}
	};

	useEffect(() => {
		setPage(pageNum);
		setRowsPerPage(pageSize);
	}, [pageNum, pageSize]);

	return (
		<div className='flex justify-between items-center w-full'>
			<p className='page-total'>
				Page {page + 1} of {totalPages}
			</p>
			<TablePagination
				component='div'
				count={totalRows}
				page={totalRows <= 1 ? 0 : page}
				onPageChange={handleChangePage}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage='Per page'
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
			/>
		</div>
	);
};

export default MUIGridPagination;
