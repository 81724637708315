import { allowCharacters } from 'helpers/keyboardRestrictions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddProductToLane } from 'reducers/POS/PosActions';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';

function ProductInfoModal({
	showProductInfoModal,
	setShowProductInfoModal,
	product,
}: ProductInfoModalProps) {
	const [selectedQuantity, setSelectedQuantity] = useState(1);
	const dispatch: any = useDispatch();

	const addToLane = async () => {
		dispatch(AddProductToLane({ ...product, quantity: selectedQuantity }));
		setShowProductInfoModal(false);
	};

	return (
		<Modal
			size='sm'
			title={
				<h1 className='text-gray-600 text-3xl font-semibold'>{product.name}</h1>
			}
			modalId='productInfoModal'
			closeIcon
			open={showProductInfoModal}
			setOpen={() => setShowProductInfoModal(false)}
			content={
				<>
					<div className='flex flex-col space-y-8'>
						<div className='flex gap-5 items-center'>
							<div className='w-2/3'>
								<input
									type='number'
									value={selectedQuantity}
									onChange={(e: any) => setSelectedQuantity(e.target.value)}
									className='w-full rounded-md p-2 border-primary-500 border-2 focus:outline-primary-500'
									max={100}
									min={1}
									onKeyPress={(e: any) => {
										allowCharacters('numeric', e);
									}}
								/>
							</div>

							<Button title='ADD TO LANE' color='primary' onClick={addToLane} />
						</div>
					</div>
				</>
			}
		/>
	);
}

export default ProductInfoModal;

export interface ProductInfoModalProps {
	showProductInfoModal: boolean;
	setShowProductInfoModal: (showProductInfoModal: boolean) => void;
	product: any;
}
