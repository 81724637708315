export const allowCharacters = (type, e) => {
	let result = false;
	if (type === 'alpha') {
		if (
			(e.which >= 65 && e.which <= 90) ||
			(e.which >= 97 && e.which <= 122) ||
			e.which === 32 ||
			e.which === 8
		) {
			result = true;
		} else {
			e.preventDefault();
		}
	} else if (type === 'alpha-numeric') {
		if (
			(e.which >= 48 && e.which <= 57) ||
			(e.which >= 65 && e.which <= 90) ||
			(e.which >= 97 && e.which <= 122) ||
			e.which === 32 ||
			e.which === 8
		) {
			result = true;
		} else {
			e.preventDefault();
		}
	} else if (type === 'numeric') {
		if ((e.which >= 48 && e.which <= 57) || e.which === 32 || e.which === 8) {
			result = true;
		} else {
			e.preventDefault();
		}
	} else if (type === 'date') {
		if (
			(e.key >= '0' && e.key <= '9') ||
			['-', 'ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'].includes(e.key)
		) {
			result = true;
		} else {
			e.preventDefault();
		}
	} else if (type === 'alpha-numeric-with-newline') {
		if (
			(e.which >= 48 && e.which <= 57) ||
			(e.which >= 65 && e.which <= 90) ||
			(e.which >= 97 && e.which <= 122) ||
			e.which === 32 ||
			e.which === 8 ||
			e.which === 13
		) {
			result = true;
		} else {
			e.preventDefault();
		}
	} else if (type === 'numeric-with-decimal') {
		if (
			(e.which >= 48 && e.which <= 57) ||
			e.which === 32 ||
			e.which === 8 ||
			e.which === 46
		) {
			result = true;
		} else {
			e.preventDefault();
		}
	} else if (type === 'sender-email-address') {
		if (e.which !== 64) {
			result = true;
		} else {
			e.preventDefault();
		}
	} else if (type === 'alpha-without-space') {
		if (
			(e.which >= 65 && e.which <= 90) ||
			(e.which >= 97 && e.which <= 122) ||
			e.which === 8
		) {
			result = true;
		} else {
			e.preventDefault();
		}
	}

	return result ?? e.preventDefault();
};
