import React from 'react';
import './style.scss';

export const Spinner = () => (
	<div
		className='fixed top-0 right-0 left-0 bottom-0 z-50 bg-blackRgba'
		style={{ zIndex: 99999 }}
	>
		<div className='fixed left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 z-50'>
			<div className='loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32' />
		</div>
	</div>
);
